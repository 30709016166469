import {
  Card, CardContent, Typography, Button,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchAdmin } from '../../services/fetch'; // Assuming fetchAdmin is used to make API calls.
import { decryption } from '../../services/encryption';

interface MessageExtendDetailsProps {
  userId: number;
  dialogId: string;
  messageId: string;
  message: string;
}

interface MessageItem {
  sender_id: number;
  sender_name: string;
  message: string;
  _id: string;
  created_at: string;
  audio_path?: string;
}

interface MessageData {
  before: {
    items: MessageItem[];
  };
  after: {
    items: MessageItem[];
  };
}

function MessageExtendDetailsCard({
  userId, dialogId, messageId, message,
}: MessageExtendDetailsProps) {
  const [messageData, setMessageData] = useState<MessageData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAdmin({
      path: `quickblox/messagesExtend/${userId}/${dialogId}/${messageId}`,
      method: 'GET',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Unauthorized Access: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setMessageData(data);
      })
      .catch((err) => {
        console.error('Failed to fetch:', err); // eslint-disable-line no-console
        setError(err.message);
      });
  }, [userId, dialogId, messageId, message]);

  if (error) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Reported QB Message Extend Details
          </Typography>
          <Typography variant="h6" color="error">
            {error.includes('Unauthorized') ? 'Message has been deleted from Quickblox' : 'An error occurred while fetching data.'}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!messageData) {
    return <Typography>Loading...</Typography>;
  }

  const sortedBeforeMessages = [...messageData.before.items].sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  const renderMergedMessages = (
    beforeMessages: MessageItem[],
    reportedMessage: string,
    afterMessages: MessageItem[],
  ) => (
    <TableContainer
      sx={{
        maxHeight: '600px',
        overflowY: 'auto',
        border: '1px solid #ccc',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{
              width: '15%',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
            >
              User ID
            </TableCell>
            <TableCell sx={{ width: '85%', fontWeight: 'bold' }}>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {beforeMessages.map((msg) => (
            <TableRow key={msg._id}>
              <TableCell sx={{ width: '15%' }}>{msg.sender_id}</TableCell>
              <TableCell
                sx={{
                  width: '85%',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {decryption({
                  dialogId,
                  data: msg.message,
                })}
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell sx={{ width: '15%', backgroundColor: '#ffe6e6' }}>
              {userId}
            </TableCell>
            <TableCell
              sx={{
                width: '85%',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                backgroundColor: '#ffe6e6',
              }}
            >
              {reportedMessage}
            </TableCell>
          </TableRow>

          {afterMessages.map((msg) => (
            <TableRow key={msg._id}>
              <TableCell sx={{ width: '15%' }}>{msg.sender_id}</TableCell>
              <TableCell
                sx={{
                  width: '85%',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {decryption({
                  dialogId,
                  data: msg.message,
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Reported QB Message Extend Details
        </Typography>
        {messageData && renderMergedMessages(
          sortedBeforeMessages,
          message, // reported message
          messageData.after.items,
        )}
      </CardContent>
    </Card>
  );
}

export default MessageExtendDetailsCard;
